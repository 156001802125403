<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <button class="btn btn-sm btn-success ml-2" @click="addSurvey()">
                <CIcon name="cilMedicalCross" />
                Tambah
              </button>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label>
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData()"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :fields="fields"
          sorter
        >
        <template #photo="{ item }">
          <td class="py-2">
            <img
              :src="item.photo"
              width="80px"
              style="cursor: pointer"
              @click="showLargeImageModal = true; largeImageSrc = item.photo"
            />
          </td>
        </template>
          <template #select="{ item }">
            <td class="py-2">
              <input
                type="checkbox"
                @change="check(item)"
                v-model="item.select"
              />
            </td>
          </template>
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                @click="edit(item)"
                class="mr-2"
                color="warning"
                square
                size="sm"
              >
                Edit
              </CButton>
              <CButton @click="hapus(item)" color="danger" square size="sm">
                Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
        <pagination
          v-if="total !== items.length"
          v-model="page"
          :records="total"
          :per-page="100"
          @paginate="pagination"
        />
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="isUpdate ? 'Edit Survey' : 'Tambah Survey'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col">
          <CInput v-model="form.name" label="Nama Lengkap" placeholder="ketik disini" />
          <CInput
            v-model="form.nickname"
            label="Nama Panggilan"
            placeholder="ketik disini"
          />
          <label class="form-label" for="gender">Jenis Kelamin</label>
          <select v-model="form.gender" class="form-control">
            <option value="1">Pria</option>
            <option value="2">Wanita</option>
          </select>
          <label class="form-label" for="newData.image">Photo</label>
          <input
            type="file"
            class="form-control mb-2"
            id="newData.image"
            @change="selectImage"
          />
          <CInput
            v-model="form.birthday"
            label="Tanggal Ulang Tahun"
            placeholder="ketik disini"
            type="date"
          />
          <label class="form-label" for="marital_status">Status Pernikahan</label>
          <select v-model="form.marital_status" class="form-control">
            <option value="1">Belum kawin</option>
            <option value="2">Kawin</option>
            <option value="3">Cerai hidup</option>
            <option value="4">Cerai mati</option>
          </select>
          <CInput
            v-model="form.number_of_children"
            label="Jumlah Anak"
            placeholder="ketik disini"
          />
          <CInput v-model="form.address" label="Alamat Rumah" placeholder="ketik disini" />
          <CInput
            v-model="form.no_handphone"
            label="Nomor Handphone"
            placeholder="ketik disini"
          />
          <label for="Pekerjaan">Pekerjaan</label>
          <v-select
          class="mb-3"
          v-model="form.job"
          placeholder="Pilih Job"
          :options="computedJob"
          ></v-select>
          <CInput
          v-model="form.company"
          label="Nama Lembaga / Perusahaan"
          placeholder="ketik disini"
          required
          />
          <CInput
          v-model="form.position"
          label="Posisi / Jabatan"
          placeholder="ketik disini"
          required
          />
          <CInput
            v-model="form.hobby"
            label="Hobby"
            placeholder="ketik disini"
          />
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah Survey
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update Survey
          </button>
        </div>
      </template>
    </CModal>
    <CModal
      size="lg"
      centered
      :show.sync="showLargeImageModal"
    >
      <img :src="largeImageSrc" alt="Large Image" style="max-width: 100%;" />
      <template slot="footer">
        <CButton
          @click="showLargeImageModal = false"
          color="secondary"
          class="mr-3"
        >
          Close
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/survey"
import { uploadImage } from "@/utils/fileUpload"
import FileSaver from "file-saver"
import firebase from "firebase/compat/app"
import "firebase/auth"

export default {
  data() {
    return {
      showLargeImageModal: false,
      largeImageSrc: '', 
      file: null,
      createModal: false,
      createModalImport: false,
      fields: [],
      isUpdate: false,
      items: [],
      jobs: [],
      users: [],
      survey: JSON.parse(localStorage.getItem("survey")),
      menus: [],
      selectedItems: [],
      isSelectedAll: false,
      selectedAction: 0,
      page: 1,
      total: 0,
      to: 0,
      form: {},
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
      modalPassword: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: "",
      verificators: [{ value: 0, label: "-" }],
    }
  },
  methods: {
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.photo = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },

    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submit() {
      var loading = this.$loading.show();
      // Check if a file is selected
      if (this.file) {
        // Upload the image file
        uploadImage(this.file)
          .then((imagePath) => {
            // Set the image path to the form data
            this.form.photo = imagePath;
            // Continue with the submission
            this.submitSurvey(loading);
          })
          .catch((e) => {
            loading.hide();
            this.$toast.error("Error uploading the image.");
          });
      } else {
        // No file selected, proceed with the submission without the image
        this.submitSurvey(loading);
      }
    },

    submitSurvey(loading) {
      // Insert data into the database
      this.form.job = this.form.job ? this.form.job.value : null

      this.$store
        .dispatch("survey/addSurvey", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan survey");
          loading.hide();
          this.createModal = false;
          this.form = {};
          this.getData();
        })
        .catch((e) => {
          console.error(e);
          this.$toast.error(e);
          loading.hide();
        });
    },
    edit(item) {
      this.form = item
      this.isUpdate = true
      this.createModal = true
    },
    update() {
      var loading = this.$loading.show()
      this.form.job = this.form.job ? this.form.job.value : null
      this.$store
        .dispatch("survey/updateSurvey", { id: this.form.id, data: this.form })
        .then(() => {
          this.$toast.success("Berhasil merubah data survey")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("survey/deleteSurvey", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data survey")
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("survey/getSurvey", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          this.to = resp.data.to

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })

      this.getJob()
    },
    getDataMenu() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("menu/get", this.params)
        .then((resp) => {
          this.menus = resp.data.data

          if (this.form.menus) {
            this.menus.forEach((element) => {
              let available = this.form.menus.filter((param) => {
                return param.id == element.id
              })

              if (available.length > 0) {
                element.checked = true
              }
            })
          }

          loading.hide()
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
          loading.hide()
        })
    },
    getDataPD() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("perangkat_daerah/get", params)
        .then((resp) => {
          this.users = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    addModalImport() {
      this.createModalImport = true
    },
    addSurvey() {
      this.isUpdate = false
      this.form = {}
      this.createModal = true
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },

    selectFileAfter(event) {
      this.file = event.target.files[0]
      var loading = this.$loading.show()
      uploadImage(this.file)
        .then((resp) => {
          this.imageListAfter.push(resp)
          loading.hide()
          alert("File berhasil diupload !!")
        })
        .catch((e) => {
          loading.hide()
          alert("Terjadi kesalahan !! | " + e)
        })
    },
    uploadProcess() {
      this.$refs.uploadFieldProcess.click()
    },
    selectFileProcess(event) {
      this.file = event.target.files[0]
      var loading = this.$loading.show()
      uploadImage(this.file)
        .then((resp) => {
          this.imageListProcess.push(resp)
          loading.hide()
          alert("File berhasil diupload !!")
        })
        .catch((e) => {
          loading.hide()
          alert("Terjadi kesalahan !! | " + e)
        })
    },
    uploadBefore() {
      this.$refs.uploadFieldBefore.click()
    },
    selectFileBefore(event) {
      this.file = event.target.files[0]
      var loading = this.$loading.show()
      uploadImage(this.file)
        .then((resp) => {
          this.imageListBefore.push(resp)
          loading.hide()
          alert("File berhasil diupload !!")
        })
        .catch((e) => {
          loading.hide()
          alert("Terjadi kesalahan !! | " + e)
        })
    },
    cancel() {
      this.form = {}
      this.createModal = false
    },
    getJob() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("job/getJob", this.params)
        .then((resp) => {
          this.jobs = resp.data.data
          this.to = resp.data.to

          // khusus untuk checkbox
          this.selectedItems = []
          this.jobs.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item, index) => {
        return {
          index: this.to - this.items.length + index + 1 + ".",
          ...item,
          birthday_name: this.$moment(item.birthday).format("Do MMMM YYYY"),
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
          job_name: item.jobs ? item.jobs.name : "Tidak ada",
          company: item.company ? item.company : "Tidak ada",
          position: item.position ? item.position : "Tidak ada",
          gender_name: 
            item.gender == "1" ? "Pria" :
            item.gender == "2" ? "Wanita" :
            item.gender == "3" ? "Lainnya" :
            item.gender,
          marital_status_name: 
            item.marital_status == "1" ? "Belum Kawin" :
            item.marital_status == "2" ? "Kawin" :
            item.marital_status == "3" ? "Cerai Hidup" :
            item.marital_status == "4" ? "Cerai Mati" :
            item.marital_status,
        }
      })
    },
    computedJob() {
      if (this.form.job) {
        let job = {
          value: this.form.job,
          label: this.jobs.find((element) => {
            return element.id == this.form.job
          }).name,
        }
        this.form.job = job
      }
      return this.jobs.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    },
  },
  mounted() {
    this.getData()
  },
  created() {
    this.fields = data.fields
  },
}
</script>
