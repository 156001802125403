export const fields = [
    { key: 'photo', label: "Foto", _style:'min-width:100px' },
    { key: 'name', label: "Nama Lengkap", _style:'min-width:100px' },
    { key: 'nickname', label: "Nama Panggilan", _style:'min-width:100px' },
    { key: "gender_name", label: "Jenis Kelamin", _style:'min-width:50px'},
    { key: "birthday_name", label: "Tanggal Ulang Tahun", _style:'min-width:50px'},
    { key: "marital_status_name", label: "Status Pernikahan", _style:'min-width:50px'},
    { key: "number_of_children", label: "Jumlah Anak", _style:'min-width:50px'},
    { key: 'address', label: "Alamat Rumah", _style:'min-width:50px' },
    { key: "no_handphone", label: "No Handphone", _style:'min-width:50px'},
    { key: "job_name", label: "Pekerjaan", _style:'min-width:50px'},
    { key: "company", label: "Perusahaan", _style:'min-width:50px'},
    { key: "position", label: "Jabatan", _style:'min-width:50px'},
    { key: "hobby", label: "Hobby", _style:'min-width:50px'},
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diupdate", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]